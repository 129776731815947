<template>
  <v-container>
    <!-- Business owner reg form -->
    <v-stepper v-model="e1">
      <v-stepper-header :color="$store.state.secondaryColor">
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          :color="$store.state.secondaryColor"
        >
          <span class="font-14"> {{ $t("personal information") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 2" step="2">
          <span class="font-14">{{ $t("shop information") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 3" step="3">
          <span class="font-14">{{ $t("physical address") }}</span>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="e1 > 4" step="4">
          <span class="font-14">{{ $t("licence information") }}</span>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form ref="personalInfo">
            <v-row justify="end">
              <v-col cols="3" class="text-right">
                <v-btn
                  small
                  dark
                  class="mr-4"
                  :color="$store.state.primaryColor"
                  @click="demoPersonalInfo"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">Demo</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-container class="my-1">
              <v-layout row wrap justify-space-between>
                <v-flex xs12 sm3 md3 lg3 class="px-1 text-center elevation-2">
                  <v-card class="pa-2" max-width="344">
                    <v-img :src="imagePreview" height="200px"></v-img>
                  </v-card>

                  <input
                    type="file"
                    @change="previewPicture"
                    class="caption mt-2"
                  />
                </v-flex>
                <v-flex xs12 sm9 md9 lg9 class="px-4">
                  <v-layout row wrap class="mt-1 caption">
                    <v-flex xs12 sm6 md6 lg6 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("owner id") }}:
                        <span>
                          <v-btn
                            height="5"
                            small
                            text
                            class="blue--text text-lowercase text-decoration-underline"
                            @click="generateId"
                          >
                            {{ $t("autogenerate") }}</v-btn
                          >
                        </span>
                      </span>
                      <v-text-field
                        outlined
                        maxlength="8"
                        @keypress="digits"
                        dense
                        v-model="ownerId"
                        :rules="rules.id"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 lg6 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("firstname") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="personalInformation.names.first"
                        :rules="rules.firstName"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="mt-3 caption">
                    <v-flex xs12 sm6 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("middlename") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="personalInformation.names.middle"
                        :rules="rules.middleName"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("lastname") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="personalInformation.names.last"
                        :rules="rules.lastName"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize">
                        {{ $t("email") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="personalInformation.email"
                        :rules="rules.email"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="mt-3 caption">
                    <v-flex xs12 sm6 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("gender") }}:
                      </span>
                      <v-radio-group
                        v-model="personalInformation.gender"
                        row
                        :rules="rules.gender"
                      >
                        <v-radio value="M">
                          <template v-slot:label>
                            <div>M</div>
                          </template>
                        </v-radio>

                        <v-radio value="F">
                          <template v-slot:label>
                            <div>F</div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-flex>
                    <v-flex xs12 sm6 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("telephone") }}:
                      </span>

                      <vue-tel-input
                        defaultCountry="Ke"
                        mode="International"
                        @keypress="digits"
                        v-model="personalInformation.telephone"
                        :rules="rules.emptyField"
                        style="height: 40px"
                      ></vue-tel-input>
                    </v-flex>
                    <v-flex xs12 sm6 md4 lg4 class="px-1">
                      <span class="font-weight-medium text-capitalize"
                        >{{ $t("PO BOX") }}:
                      </span>
                      <v-text-field
                        outlined
                        dense
                        v-model="personalInformation.address.pobox"
                        :rules="rules.emptyField"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm4 md4 lg4 class="text-right">
              <v-btn
                dark
                small
                :color="$store.state.primaryColor"
                @click="continueStepper('personalInfo')"
                >{{ $t("continue") }}
                <v-icon right dark> mdi-arrow-right-circle </v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="2">
          <v-form ref="ShopInfo">
            <v-row justify="end">
              <v-col cols="3" class="text-right">
                <v-btn
                  small
                  dark
                  class="mr-4"
                  :color="$store.state.primaryColor"
                  @click="demoShopInfo"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">Demo</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-container class="my-1">
              <v-layout row wrap justify-space-around class="mb-3">
                <v-flex xs12 sm4 md4 lg4 class="text-center">
                  <v-avatar tile size="150" color="grey lighten-2">
                    <v-img :src="logoPreview" alt="business logo"></v-img>
                  </v-avatar>
                  <v-layout row wrap class="mt-2 mb-2">
                    <v-flex xs12 sm12 md12 lg12>
                      <span class="caption">{{ $t("business logo") }}*</span>
                      <input
                        type="file"
                        @change="previewLogo"
                        dense
                        small-chips
                        :rules="rules.emptyField"
                        class="caption mt-2"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm4 md4 lg4 class="text-center">
                  <v-avatar tile size="150" color="grey lighten-2">
                    <v-img :src="bannerPreview" alt="business banner"></v-img>
                  </v-avatar>

                  <v-layout row wrap class="mt-2 mb-2">
                    <v-flex xs12 sm12 md12 lg12>
                      <span class="caption">{{ $t("business banner") }}*</span>
                      <input
                        type="file"
                        @change="previewBanner"
                        dense
                        small-chips
                        :rules="rules.emptyField"
                        class="caption mt-2"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-1 caption">
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("business name") }}*:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.businessName"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("abbreviation") }}:</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.abbreviation"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("type of business") }}*:</span
                  >

                  <v-combobox
                    v-model="shopInformation.businessType"
                    :items="businessTypes"
                    @change="onSelectBusinessType"
                    :rules="rules.emptyField"
                    outlined
                    dense
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("website") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.website"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap class="mt-3 caption">
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("email") }}*:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.email"
                    :rules="rules.email"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("telephone") }}*:</span
                  >

                  <vue-tel-input
                    defaultCountry="Ke"
                    @keypress="digits"
                    mode="International"
                    v-model="shopInformation.phone"
                    :rules="rules.emptyField"
                    style="height: 40px"
                  ></vue-tel-input>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("PO BOX") }}*:</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.address.pobox"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("registration number") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.regNo"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-3 caption">
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("permit number") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.permitNo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("pin") }}
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.pin"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("bank name") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.bank.bankName"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("bank account number") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.bank.accountNo"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-3 caption">
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("mpesa till number") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.mpesa.tillNo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("mpesa paybill number") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.mpesa.paybillNo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("mpesa account number") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.mpesa.accountNo"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("glopay") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.glopay"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-3 caption">
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("country") }}*:
                  </span>
                  <v-combobox
                    v-model="shopInformation.address.country"
                    :items="countries"
                    @change="selectCountry"
                    :rules="rules.emptyField"
                    outlined
                    dense
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("county-province") }}*:</span
                  >

                  <v-combobox
                    outlined
                    dense
                    v-model="shopInformation.address.county"
                    :items="provinces"
                    @change="selectCounty"
                    :rules="rules.emptyField"
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("city-town") }}*:</span
                  >

                  <v-combobox
                    outlined
                    dense
                    :items="towns"
                    v-model="shopInformation.address.city"
                  ></v-combobox>
                </v-flex>

                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("district-territory") }}:
                  </span>
                  <v-combobox
                    outlined
                    dense
                    v-model="shopInformation.address.territory"
                    :items="territories"
                  ></v-combobox>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="mt-3 caption">
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("estate") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.address.estate"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("street") }}*:</span
                  >

                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.address.street"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("building") }}*:</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.address.building"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>

                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("house-suite-number") }}*:</span
                  >
                  <v-text-field
                    outlined
                    dense
                    v-model="shopInformation.address.no"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm4 md4 lg4 class="text-right">
              <v-btn
                class="mr-1"
                dark
                small
                :color="$store.state.secondaryColor"
                @click="previousStepper"
                ><v-icon left dark> mdi-arrow-left-circle </v-icon
                >{{ $t("back") }}</v-btn
              >
              <v-btn
                dark
                small
                :color="$store.state.primaryColor"
                @click="continueStepper('ShopInfo')"
                >{{ $t("continue") }}
                <v-icon right dark> mdi-arrow-right-circle </v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="3">
          <v-form ref="address">
            <v-row justify="end">
              <v-col cols="3" class="text-right">
                <v-btn
                  small
                  dark
                  :color="$store.state.primaryColor"
                  class="mr-4"
                  @click="demoAddress"
                >
                  <v-icon small left>gamepad</v-icon>
                  <span class="caption text-capitalize">Demo</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-container class="my-1">
              <v-layout row wrap class="mt-3 caption">
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("country") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="personalInformation.address.country"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize">
                    {{ $t("county") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="personalInformation.address.county"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("city") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="personalInformation.address.city"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("street") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="personalInformation.address.street"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap class="mt-3 caption">
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("estate") }}:
                  </span>
                  <span> </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="personalInformation.address.estate"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize">
                    {{ $t("building") }}:
                  </span>
                  <v-text-field
                    outlined
                    dense
                    v-model="personalInformation.address.building"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4 lg4 class="px-1">
                  <span class="font-weight-medium text-capitalize"
                    >{{ $t("house-suite-number") }}:
                  </span>
                  <v-text-field
                    dense
                    outlined
                    v-model="personalInformation.address.no"
                    :rules="rules.emptyField"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-layout row wrap class="mb-3 mr-1">
            <v-spacer></v-spacer>
            <v-flex xs12 sm4 md4 lg4 class="text-right">
              <v-btn
                class="mr-1"
                dark
                small
                :color="$store.state.secondaryColor"
                @click="previousStepper"
                ><v-icon left dark> mdi-arrow-left-circle </v-icon
                >{{ $t("back") }}</v-btn
              >

              <v-btn
                dark
                small
                :color="$store.state.primaryColor"
                @click="continueStepper('address')"
                >{{ $t("continue") }}
                <v-icon right dark> mdi-arrow-right-circle </v-icon></v-btn
              >
            </v-flex>
          </v-layout>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="LicenseInfo">
            <v-container class="my-1">
              <v-row justify="space-between">
                <v-col cols="12">
                  <v-list dense class="elevation-1 pa-0">
                    <v-list-item class="caption justify-space-between">
                      <v-col cols="12">
                        <span class="">{{ $t("select package") }}: </span>
                        <v-menu>
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  color="primary"
                                  dark
                                  block
                                  outlined
                                  v-bind="attrs"
                                  v-on="{ ...tooltip, ...menu }"
                                >
                                  <v-icon left> mdi-gift </v-icon>
                                  {{ packageName }}
                                  <v-icon right> mdi-menu-down </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("click for license options") }}</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item
                              v-for="(item, index) in packages"
                              :key="index"
                              @change="onSelectLicence(item.id, item.name)"
                            >
                              <v-list-item-title>{{
                                item.name.toUpperCase()
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>

              <v-layout row wrap>
                <v-flex
                  xs12
                  sm6
                  md4
                  lg4
                  class="pa-2"
                  v-for="packaged in packages"
                  :key="packaged.id"
                >
                  <v-card
                    :color="packaged.packageColor"
                    class="text-center"
                    tile
                  >
                    <v-card-title class="justify-center white--text">{{
                      packaged.name.toUpperCase()
                    }}</v-card-title>
                    <v-card-text class="display-1 white--text">
                      USD {{ packaged.price }}</v-card-text
                    >
                    <v-card-text
                      class="grey--text text--lighten-2"
                      v-if="packaged.price == 'Free'"
                      >{{ $t("valid for 14 days") }}</v-card-text
                    >
                    <v-card-text
                      class="grey--text text--lighten-2"
                      v-else-if="packaged.name == 'Lifetime'"
                      >{{ $t("once") }}</v-card-text
                    >

                    <v-card-text class="grey--text text--lighten-2" v-else>{{
                      $t("per month")
                    }}</v-card-text>
                    <v-list class="pa-0">
                      <v-list-item class="mx-4 caption">
                        <v-row justify="space-between">
                          <span class="text-capitalize">{{
                            $t("branches")
                          }}</span>
                          <span
                            class="light-blue lighten-4 px-2 py-1 rounded primary--text text--darken-4"
                            >{{ packaged.description.branches }}</span
                          >
                        </v-row>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item class="mx-4 caption">
                        <v-row justify="space-between">
                          <span class="text-capitalize">{{
                            $t("user accounts")
                          }}</span>
                          <span
                            class="light-blue lighten-4 px-2 py-1 rounded primary--text text--darken-4"
                            >{{ packaged.description.users }}</span
                          >
                        </v-row>
                      </v-list-item>

                      <v-divider></v-divider>

                      <v-list-item class="mx-4 caption">
                        <v-row justify="space-between">
                          <span class="text-capitalize">{{
                            $t("storage")
                          }}</span>
                          <span
                            class="light-blue lighten-4 px-2 py-1 rounded primary--text text--darken-4"
                            >{{ packaged.description.storage }} MB</span
                          >
                        </v-row>
                      </v-list-item>

                      <v-divider></v-divider>
                    </v-list>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>

          <v-row justify="end" class="ma-3">
            <v-col cols="2" class="text-right">
              <v-btn
                dark
                small
                :color="$store.state.secondaryColor"
                @click="previousStepper"
                ><v-icon left dark> mdi-arrow-left-circle </v-icon
                >{{ $t("back") }}
              </v-btn>
            </v-col>
            <v-col cols="2" class="text-right">
              <!-- save button here -->

              <v-btn
                small
                dark
                :color="$store.state.primaryColor"
                class="mr-4"
                @click="saveData"
                :loading="loading"
                >{{ $t("register") }} <v-icon small right>save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>

      <v-stepper-items>
        <v-stepper-content step="4"> </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-dialog v-model="dialog" max-width="400" persistent>
      <v-card dark height="30">
        <v-card-text class="py-2">
          <v-progress-linear
            height="10"
            :v-model="progressValue"
            :buffer-value="progressValue"
            stream
            striped
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span> {{ $t("owner data saved successfully") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>
        {{ $t("ID") }} <strong>{{ ownerId }}</strong>
        {{ $t("already exists.") }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="red darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import fb from "firebase/app";

import countryList from "@/data/countries";
import { format, addMonths, addDays } from "date-fns";
export default {
  data: () => ({
    imagePreview:
      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",
    ownerId: "",
    owners: [],

    packages: [],
    provinces: [],
    towns: [],
    territories: [],
    countryList,
    packageName: "",
    colors: [
      "success",
      "light-blue",
      "primary darken-2",
      "pink darken-4",
      "error darken-4",
      "teal darken-1",
    ],

    // handle personal information data
    personalInformation: {
      address: {
        country: "",
        county: "",
        city: "",
        pobox: "",
        street: "",
        estate: "",
        building: "",
        no: "",
      },
      names: {
        first: "",
        middle: "",
        last: "",
      },
      email: "",
      telephone: "",
      avatar: "",
      gender: "",

      ownerId: "",

      dateCreated: new Date(),
    },
    // handle shop information data
    shopInformation: {
      ownerId: "",
      logo: "",
      banner: "",
      businessName: "",
      abbreviation: "",
      businessType: "",
      regNo: "",
      permitNo: "",
      website: "",
      email: "",
      phone: "",
      address: {
        country: "",
        county: "",
        city: "",
        pobox: "",
        street: "",
        estate: "",
        building: "",
        no: "",
      },
      bank: {
        bankName: "",
        accountNo: "",
      },
      mpesa: {
        tillNo: "",
        paybillNo: "",
        accountNo: "",
      },
      glopay: "",
      pin: "",

      verified: true,
      dateCreated: format(new Date(), "yyyy-MM-dd"),
    },
    // handle license information data
    licenseInformation: {
      freeTrial: new Date(),
      freeTrialExpiryDate: addDays(new Date(), 14),
      ownerId: "",
      isValid: false,
      packageId: "pvYWZF2mlxFte7PSQ4qr",
      startDate: new Date(),
      endDate: "",
    },

    logoPreview:
      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",
    bannerPreview:
      " https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",

    logo: "",
    banner: "",
    createdBusiness: "",

    e1: 1,
    file: "",
    loading: false,
    dialog: false,
    progressValue: 10,
    snackSuccess: false,
    snackError: false,
  }),
  created() {
    this.getPackages();
    this.getSystemUsersID();
  },
  computed: {
    //fecth the stored business
    businessTypes() {
      return this.$store.getters.getBusinessTypes;
    },

    countries() {
      return this.countryList.map((item) => item.country);
    },
    rules() {
      return {
        firstName: [
          (v) => !!v || this.$t("first name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        middleName: [
          (v) => !!v || this.$t("middle name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        lastName: [
          (v) => !!v || this.$t("last name is required"),
          (v) =>
            (v && v.length <= 10) ||
            this.$t("name must be less than 15 characters"),
        ],
        id: [
          (v) => !!v || this.$t("id number is required"),
          (v) => (v && v.length == 8) || this.$t("must be 8 characters"),
        ],
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || this.$t("please enter a valid email"),
        ],
        gender: [(v) => (v || "").length > 0 || this.$t("please select one")],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },
  methods: {
    onSelectLicence(packageId, packageName) {
      this.licenseInformation.ownerId = this.ownerId;
      this.packageName = packageName;
      this.licenseInformation.packageId = packageId;
      this.licenseInformation.endDate = addMonths(new Date(), 12);
    },

    onSelectBusinessType(businessType) {
      this.shopInformation.businessType = businessType;
    },

    // get packages
    getPackages() {
      this.packages = [];
      db.collection("packages")
        .orderBy("description.storage")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.packages.push({
              id: snaps.id,
              ...snaps.data(),
            });
          });
        });
    },
    continueStepper(formRef) {
      if (this.$refs[formRef].validate()) {
        if (this.checkOwnersId()) {
          this.e1 += 1;
        } else {
          this.snackError = true;
        }
      }
    },

    previousStepper() {
      this.e1 -= 1;
    },

    demoPersonalInfo() {
      this.personalInformation.ownerId = this.ownerId;
      this.personalInformation.names.first = "James";
      this.personalInformation.names.middle = "M.";
      this.personalInformation.names.last = "Ndoto";
      this.personalInformation.email = "jm@email.com";
      this.personalInformation.telephone = "+254098765432";
      this.personalInformation.address.pobox = "00100";
      this.personalInformation.gender = "M";
    },

    demoShopInfo() {
      this.shopInformation.ownerId = this.ownerId;
      this.shopInformation.businessName = "Rembo Collections";
      this.shopInformation.abbreviation = "R.C";
      this.shopInformation.businessType = "Clothing Store";
      this.shopInformation.regNo = "23456781";
      this.shopInformation.permitNo = "2213341111/0000";
      this.shopInformation.address.pobox = "11567 – 00100 Nairobi ,Kenya";
      this.shopInformation.email = "rc@fashion.com";
      this.shopInformation.website = "www.rembocollections.co.ke";
      this.shopInformation.phone = "+2540700000000";
      this.shopInformation.address.country = "Kenya";
      this.shopInformation.address.county = "Nairobi";
      this.shopInformation.address.city = "Nairobi";
      this.shopInformation.address.street = "Tom Mboya Street";
      this.shopInformation.address.estate = "CBD";
      this.shopInformation.address.building = "Imenti House";
      this.shopInformation.address.no = "Z31";
      this.shopInformation.bank.bankName = "Equity Bank - Moi Avenue";
      this.shopInformation.bank.accountNo = "22733419";
      this.shopInformation.mpesa.tillNo = "010101";
      this.shopInformation.mpesa.paybillNo = "090909";
      this.shopInformation.mpesa.accountNo = "rm900";
      this.shopInformation.glopay = "N/A";
      this.shopInformation.pin = "AB00123456";
    },

    demoAddress() {
      this.personalInformation.address.country = "Kenya";
      this.personalInformation.address.county = "Nairobi";
      this.personalInformation.address.city = "Nairobi";
      this.personalInformation.address.street = "Ngong Road";
      this.personalInformation.address.estate = "Ngong Road";
      this.personalInformation.address.building = "Bishop Magua Center";
      this.personalInformation.address.no = "1st FL FF10, Wing B";
    },

    previewLogo(event) {
      this.logo = event.target.files[0];
      this.logoPreview = URL.createObjectURL(this.logo);
    },

    previewBanner(event) {
      this.banner = event.target.files[0];
      this.bannerPreview = URL.createObjectURL(this.banner);
    },

    selectCountry() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.shopInformation.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates
    },

    selectCounty() {
      let index = this.countryList.findIndex(
        (x) => x.country == this.shopInformation.address.country
      );
      let region = this.countryList[index].regions; //gives regions of selected country
      let provincesList = region.map((item) => item.province); //gives provinces of selected region
      this.provinces = provincesList; //set provinces in templates

      let i = region.findIndex(
        (x) => x.province == this.shopInformation.address.county
      );
      this.towns = region[i].towns;
      this.territories = region[i].territories;
    },

    digits(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 43) {
        // 46 is dot 43 is +
        event.preventDefault();
      }
    },

    previewPicture(event) {
      this.file = event.target.files[0];
      this.imagePreview = URL.createObjectURL(this.file);
    },

    getSystemUsersID() {
      this.owners = [];
      db.collection("owners")
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            this.owners.push(snaps.id);
          });
        });
    },

    checkOwnersId() {
      if (this.owners.includes(this.ownerId)) return false;
      else return true;
    },
    saveData() {
      this.personalInformation.ownerId = this.ownerId;

      if (this.$refs.address.validate()) {
        this.loading = true;
        this.dialog = true;
        db.collection("owners")
          .doc(this.ownerId)
          .set(this.personalInformation)
          .then(() => {
            this.uploadProfile();
            this.addAsSystemUser();
          });
      }
    },

    uploadProfile() {
      let storageRef = fb.storage().ref("users/" + this.ownerId);
      let uploadTask = storageRef.put(this.file);
      this.progressValue = 50;

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          error.preventDefault();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("owners")
              .doc(this.ownerId)
              .update({
                avatar: downloadLink,
              })
              .then(() => {
                this.progressValue = 70;
              });
          });
        }
      );
    },

    addAsSystemUser() {
      let data = {
        accessRight: true,
        isEmployee: false,
        isManager: false,
        isOwner: true,
        isCashier: false,
        isChef: false,
        isWaiter: false,
        isSuperAdmin: false,
        password: "upsell2020", // Default password
        username: this.ownerId,
        status: true,
      };
      db.collection("users")
        .add(data)
        .then(() => {
          this.saveBusiness();
        });
    },

    saveBusiness() {
      if (this.$refs.address.validate()) {
        this.loading = true;
        this.dialog = true;
        db.collection("businesses")
          .add(this.shopInformation)
          .then((snaps) => {
            if (this.banner != "") {
              this.uploadBanner(snaps.id);
            } else if (this.logo != "") {
              this.uploadLogo(snaps.id);
            } else {
              this.progressValue = 80;
              this.saveLicense();
            }
          });
      }
    },

    uploadBanner(id) {
      let storageRef = fb.storage().ref("businesses/banners/" + this.ownerId);
      let uploadTask = storageRef.put(this.banner);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          error.preventDefault();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("businesses")
              .doc(id)
              .update({
                banner: downloadLink,
              })
              .then(() => {
                if (this.logo != "") {
                  this.uploadLogo(id);
                } else {
                  this.progressValue = 80;
                  this.saveLicense();
                }
              });
          });
        }
      );
    },

    uploadLogo(id) {
      let storageRef = fb.storage().ref("businesses/logos/" + this.ownerId);
      let uploadTask = storageRef.put(this.logo);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => {
          error.preventDefault();
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
            db.collection("businesses")
              .doc(id)
              .update({
                logo: downloadLink,
              })
              .then(() => {
                this.progressValue = 80;
                this.saveLicense();
              });
          });
        }
      );
    },
    generateId() {
      let ownerId = Math.floor(Math.random() * 100000000 + 1);
      this.ownerId = String(ownerId);
    },

    saveLicense() {
      this.licenseInformation.ownerId = this.ownerId;
      if (this.$refs.address.validate()) {
        this.loading = true;
        this.dialog = true;
        db.collection("licenses")
          .add(this.licenseInformation)
          .then(() => {
            this.progressValue = 100;
            this.dialog = false;
            this.loading = false;
            this.snackSuccess = true;

            setTimeout(() => this.$router.push({ name: "ViewOwner" }), 2000);
          });
      }
    },
  },
};
</script>

<style></style>
